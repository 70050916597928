@charset "UTF-8";
.bg-amboss {
  color: #000 !important;
  background-color: RGBA(208, 241, 232, var(--bs-bg-opacity, 0.7)) !important;
}

.bg-amboss--disabled {
  opacity: 0.5;
}

.bg-amboss__topic-anatomie {
  color: #000 !important;
  background-color: RGBA(208, 241, 232, var(--bs-bg-opacity, 0.7)) !important;
}

.bg-amboss__topic-anatomy {
  color: #000 !important;
  background-color: RGBA(208, 241, 232, var(--bs-bg-opacity, 0.7)) !important;
}

.bg-amboss__topic-physiologie {
  color: #000 !important;
  background-color: RGBA(174, 202, 244, var(--bs-bg-opacity, 0.7)) !important;
}

.bg-amboss__topic-physiology {
  color: #000 !important;
  background-color: RGBA(174, 202, 244, var(--bs-bg-opacity, 0.7)) !important;
}

.bg-amboss__topic-innere {
  color: #000 !important;
  background-color: RGBA(250, 245, 184, var(--bs-bg-opacity, 0.7)) !important;
}

.bg-amboss__topic-internal {
  color: #000 !important;
  background-color: RGBA(250, 245, 184, var(--bs-bg-opacity, 0.7)) !important;
}

.bg-amboss__topic-chirurgie {
  color: #000 !important;
  background-color: RGBA(174, 202, 244, var(--bs-bg-opacity, 0.7)) !important;
}

.bg-amboss__topic-surgery {
  color: #000 !important;
  background-color: RGBA(174, 202, 244, var(--bs-bg-opacity, 0.7)) !important;
}

.bg-amboss__topic-biochemie {
  color: #000 !important;
  background-color: RGBA(250, 245, 184, var(--bs-bg-opacity, 0.7)) !important;
}

.bg-amboss__topic-biochemistry {
  color: #000 !important;
  background-color: RGBA(250, 245, 184, var(--bs-bg-opacity, 0.7)) !important;
}

.bg-amboss__topic-gynäkologie {
  color: #000 !important;
  background-color: RGBA(252, 234, 202, var(--bs-bg-opacity, 0.7)) !important;
}

.bg-amboss__topic-gynecology {
  color: #000 !important;
  background-color: RGBA(252, 234, 202, var(--bs-bg-opacity, 0.7)) !important;
}

.bg-amboss__topic-pharmakologie {
  color: #000 !important;
  background-color: RGBA(206, 237, 241, var(--bs-bg-opacity, 0.7)) !important;
}

.bg-amboss__topic-pharmacology {
  color: #000 !important;
  background-color: RGBA(206, 237, 241, var(--bs-bg-opacity, 0.7)) !important;
}

