.audio-header {
    display: flex;
    flex-wrap: wrap;
    width: 90%;
    margin: 4rem auto 1rem;
    justify-content: space-between;
    align-items: center;
}

.audio-tools {
    display: flex;
    flex-direction: row-reverse;
    width: 100%;
    justify-content: space-between;
    flex-basis: 50%;
    margin-left: auto;
}

.audio-tool-toggle {
    position: relative;
    z-index: 19;
}

.line-break {
    width:100%;
}

.audio-track-minimize, .audio-track-maximize {
    position: fixed;
    top: 2.5rem;
    left: 2.5rem
}

.audio-track-menu {
    position: fixed;
    top: 2.5em;
    z-index: 199;
    right: 2.5rem;
}

.language-toggle__button-group {
    position: fixed;
    top: 2.3rem;
    translate: -50%;
    height: 1.75rem;
}

.language-toggle__button {
    line-height: .75rem;
}