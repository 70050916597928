// custom-bootstrap.scss

// variable overrides
$theme-colors: (
        "amboss": #D0F1E8
);

$topic-colors: (
        "anatomie": #D0F1E8,
        "anatomy": #D0F1E8,
        "physiologie": #AECAF4,
        "physiology": #AECAF4,
        "innere": #FAF5B8,
        "internal": #FAF5B8,
        "chirurgie": #AECAF4,
        "surgery": #AECAF4,
        "biochemie": #FAF5B8,
        "biochemistry": #FAF5B8,
        "gynäkologie": #FCEACA,
        "gynecology": #FCEACA,
        "pharmakologie": #CEEDF1,
        "pharmacology": #CEEDF1,
);

// Bootstrap and its necessary parts
@import "../../node_modules/bootstrap/scss/functions";
@import "../../node_modules/bootstrap/scss/variables";
@import "../../node_modules/bootstrap/scss/mixins";

// Your custom _color-bg partial content
@each $color, $value in $theme-colors {
  $color-rgb: to-rgb($value);
  .bg-#{$color} {
    color: color-contrast($value) if($enable-important-utilities, !important, null);
    background-color: RGBA($color-rgb, var(--#{$prefix}bg-opacity, .7)) if($enable-important-utilities, !important, null);
  }
}
.bg-amboss--disabled {
  opacity: .5;
}

@each $color, $value in $topic-colors {
  $color-rgb: to-rgb($value);
  .bg-amboss__topic-#{$color} {
    color: color-contrast($value) if($enable-important-utilities, !important, null);
    background-color: RGBA($color-rgb, var(--#{$prefix}bg-opacity, .7)) if($enable-important-utilities, !important, null);
  }
}


