
.table-of-contents {
    position: absolute;
    bottom: 0;
    left: 0;
    width: 100%;
    z-index: 9999;
    background-color: rgba(250,250,250,0.9);
    display: flex;
    align-items: flex-end;
    justify-content: center;
    padding: 4rem 2rem;
    line-height: 1.8rem;
    backdrop-filter: blur(7px);
    transition: height 1s ease-in 100ms, padding 1000ms linear 200ms;

}


.table-of-contents--expanded {
    height: 100%;
}

.table-of-contents--collapsed {
    height: 0;
    padding: 0em 2.5em;
}

.table-of-contents__list {
    list-style-type: none;
    height: auto;
    max-height: 100%;
    padding: 0 2rem;
    flex-basis: fit-content;
    max-width: 30rem;
    margin: 1rem 0;
    overflow: scroll;
    transition: transform 600ms linear 500ms, padding 1000ms linear 200ms,;
    background-color: rgba(255,255,255);
    border-radius: 5%;
    transform-origin: top right;

}

.table-of-contents__list--expanded {
    padding: 2rem;
}



.table-of-contents__item {
    padding: 2px 0;
}
