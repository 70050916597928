@import url("https://fonts.googleapis.com/css?family=Lato:400,700");
@import url("https://fonts.googleapis.com/css2?family=Roboto:wght@400&display=swap");
@import url("https://cdnjs.cloudflare.com/ajax/libs/font-awesome/5.12.0-2/css/all.min.css");

.hide {
  display: none;
}

i {
  font-size: 1.2em;
}

h1 {
  font-size: 2.5em;
}
h2 {
  font-size: 1.5em;
}
h3 {
  font-size: 1.2em;
  margin-top: 0em;
  font-weight: 500;
}

h3.current-chapter {
  margin: 1rem
}


.audio-player-background {
  position: absolute;
  height: 100%;
  width: 100%;
  backdrop-filter: blur(1px);
  top: 0;
  left: 0;
  box-shadow: 1px 1px 10px 2px rgba(0, 0, 0, 0.2);
  -webkit-filter: blur(5px);
  -moz-filter: blur(5px);
  -o-filter: blur(5px);
  -ms-filter: blur(5px);
  filter: blur(5px);
  z-index: -1;
}


.linkspan {
  cursor: pointer;
}

.linkspan:active {
  font-weight: bold;
}

[class*="--disallowed"] {
  cursor: not-allowed;
  opacity: 0.6;
}

[class*="--allowed"] {
  cursor: pointer;
}


.clickable:hover {
  transform: scale(1.1);
  /*box-shadow: 0 0 3em rgba(0, 0, 0, 0.3);*/
  border-radius: 50%;
  cursor: pointer;
}

.clickable:active {
  transform: scale(1.2);
  /*box-shadow: 0 0 3em rgba(0, 0, 0, 0.5);*/
}

.resize-handle {
  width: 30px;
  height: 30px;
  position: absolute;
  bottom: 0;
  right: 0;
  cursor: nwse-resize;
}



.Book-opener {
  position: fixed;
  margin: 25% 0;
  font-size: 2em;
  padding: 0 .5rem;
  z-index: 14;
  transition: right 500ms linear 250ms, left 500ms linear 250ms;
}

.Book-opener.left {
  left: 0;
  right: unset;
}

.Book-opener.right {
  right: 0;
  left: unset;
}
















