.deadButton {
    animation: zoomShake 1s;
    animation-iteration-count: 2;
}


.loader {
    animation: spinGlow 400s linear infinite;
    transform-origin: center center;
}



@keyframes fadeIn {
    0% {
        opacity: 0;
    }
    100% {
        opacity: 1;
    }
}

@keyframes slideIn {
    0% {
        transform: translateY(100%);
    }
    100% {
        transform: translateY(0%);
    }
}

@keyframes slideOut {
    0% {
        transform: translateY(0%);
    }
    100% {
        transform: translateY(100%);
    }
}

@keyframes spinGlow {
    0% {
        transform: scale(1) rotate(0deg);
    }
    25% {
        transform: scale(0.5) rotate(90deg);
    }
    75% {
        transform: scale(1.5) rotate(270deg);
    }
    100% {
        transform: scale(1) rotate(360deg);
    }
}

@keyframes zoomShake {
    0% {
        transform: translateX(0px) scale(1) rotate(0deg);
    }
    10% {
        transform: translateX(-1px) scale(1.1) rotate(0deg);
    }
    20% {
        transform: translateX(0px) scale(1.1) rotate(5deg);
    }
    30% {
        transform: translateX(2px) scale(1.1) rotate(-5deg);
    }
    40% {
        transform: translateX(-2px) scale(1.1) rotate(-15deg);
    }
    50% {
        transform: translateX(2px) scale(1.1) rotate(-5deg);
    }
    60% {
        transform: translateX(-2px) scale(1.1) rotate(10deg);
    }
    70% {
        transform: translateX(2px) scale(1.1) rotate(-10deg);
    }
    80% {
        transform: translateX(-2px) scale(1.1) rotate(-5deg);
    }
    90% {
        transform: translateX(1px) scale(1.1) rotate(5deg);
    }
    100% {
        transform: translateX(0px) scale(1) rotate(0deg);
    }
}

@keyframes fadeInUp {
    0% {
        opacity: 0;
        transform: translateY(20px);
    }
    100% {
        opacity: 1;
        transform: translateY(0px);
    }
}

@keyframes fadeInDown {
    0% {
        opacity: 0;
        transform: translateY(-20px);
    }
    100% {
        opacity: 1;
        transform: translateY(0px);
    }
}

@keyframes fadeInLeft {
    0% {
        opacity: 0;
        transform: translateX(-20px);
    }
    100% {
        opacity: 1;
        transform: translateX(0px);
    }
}

@keyframes fadeInRight {
    0% {
        opacity: 0;
        transform: translateX(20px);
    }
    100% {
        opacity: 1;
        transform: translateX(0px);
    }
}
