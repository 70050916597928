

/* Media Query for Mobile Devices */
@media screen and (max-width: 480px) {
    .audio-player-wrapper {
        height: 100%;
        width: 100%;
        border-radius: 0;
        top: 0;
        left: 0;
        transform: none;
    }

    .audio-track-maximize, .audio-track-minimize {
        left: 1.5rem;
        top: 1.5rem;
        transform: unset;
    }

    .audio-track-menu {
        top: 1.5em;
        right: 1.5rem;
    }

    .language-toggle__button-group {
        top: 1.3rem;
    }

    .episode-view {
        height: 100%;
        width: 100%;
        left: 100%;
        margin-top: 2rem;
    }

    .audio-player-background {
        height: 100%;
        width: 100%;
    }

    .player {
        width: 100%;
    }

    main {
        margin: 1rem auto;
    }

    .tab-content {
        height: 100%
    }

    .tab-content > .active {
        margin: 1rem 0;
        display: flex;
        flex-direction: column;
        row-gap: 0.5em;
    }

    .audio-track-menu .table-of-contents {
        padding: 1em 1em;
    }

    .audio-track-title {
        margin: 0;
    }

    h1 {
        font-size: 2em;
    }
    h2 {
        font-size: 1.2em;
    }
    h3 {
        font-size: 1em;
    }

    .audio-track-control {
        margin: .5em auto 0;
    }


    .audio-controls {
        font-size: 1.5em;
    }

    .audio-controls svg,
    .audio-controls i {
        width: 1em;
        height: 1em;
    }

    .book-opener {
        margin: 20% 0;
        font-size: 1.5em;
    }
}


@media screen and (orientation: portrait) and (min-width: 680px) {

    .player{
        transition: width 500ms linear 250ms, top 500ms linear 250ms, left 500ms linear 250ms;
    }

    .player--left {
        left: 0;
        width: 60%;
    }

    .episode-view {
        margin-top: 3rem;
    }

    .episode-view.opened {
        left: 55%;
        width: 45%;
    }

    .audio-track-cover-wrapper {
        width: 18rem;
        height: 18rem;
    }

    .book-opener.left {
        left: calc(60% - .5rem);
    }
}

/* CSS rules for desktop screens with a width of at least 960px */
@media screen and (min-width: 960px) {


    .book-opener {
        margin: 0 calc(min(50vw,20rem) - 18rem) 0 0
    }

    .audio-track-cover-wrapper {
        width: 20rem;
        height: 20rem;
    }

    .player {
        transition: width 500ms linear 250ms, top 500ms linear 250ms, left 500ms linear 250ms;
    }
    .episode-view {
        margin-top: 3rem;
    }

    .episode-view.opened {
        left: 60%;
        width: 40%;
        padding: 2rem 2rem 2rem 0;
    }

    .player--left {
        left: 0;
        width: 60%;
    }

    .book-opener.left {
        right: calc(40% + 1rem);
        margin: 0;
        left: unset;
    }

}