

.audio-track-title {
    width: 100%;
    text-align: center;
    margin: 10px 0;
    word-wrap: break-word;
}

.audio-track-title > * {
    line-height: 1.5em;
    margin: 0;
}

.audio-track-title > * {
    font-family: "Lato", "Arial", sans-serif;
}


main {
    max-width: 35rem;
    width: 80%;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: space-between;
    margin: 3rem auto;
    flex: auto;
}

main-spacer {
    display: flex;
    height: 5rem;
}

.audio-track-cover-wrapper {
    display: flex;
    height: 15em;
    width: 15em;
    margin: 0em 0;
    border-radius: 10%;
    overflow: clip;
}

.audio-track-cover-wrapper > img {
    height: 100%;
    width: 100%;
    margin: auto;
}

.audio-track-control {
    display: flex;
    flex-direction: column;
    align-items: center;
    width: 100%;
    margin: 2rem auto 0;
}

/***
Audio track controls
 */

.audio-controls {
    /*  font-family: "Lato", "Arial", sans-serif; */
    display: flex;
    margin: .5em auto;
    font-size: 1.5em;
    color: black;
    justify-content: space-between;
    width: 100%;
    align-items: center;
}

.audio-controls svg, .audio-controls i {
    width: 1.2em;
    height: 1.2em
}

.audio-controls > .audio-controls__play-pause {
    color: black;
}

.audio-controls__forward-ten, .audio-controls__rewind-ten {
    padding-bottom: 0.4em;
}

.audio-controls__volume {
    display: flex;
}

.audio-controls__volume-vertical {
    position: absolute;
    display:none;
    backdrop-filter: blur(1px);
    transform: rotate(-90deg);
    translate: 0 -10px;
    transform-origin: 0 0;
}


/***
Progress bar slider
 */

.progress-slider {
    width: 100%;
    height: 1em;
    margin: auto;
    padding: 0;
    background-color: #eee;
    border-radius: 1em;
    position: relative;
    cursor: pointer;
    border: 5px solid transparent;
    background-clip: content-box;

}

.progress_slider__played {
    background-color: black;
    display: inline-block;
    height: 100%;
    position: absolute;
    z-index: 14;
    border-radius: 1em .5em .5em 1em;
}

.progress-slider__buffered {
    background-color: lightgray;
    display: inline-block;
    left: 0;
    height: 100%;
    position: absolute;
    z-index: 12;
    border-radius: 1em .5em .5em 1em;
}

.progress-slider__handle {
    height: 1em;
    width: 1em;
    border-radius: 50%;
    display: inline-block;
    cursor: pointer;
    background-color: white;
    border:1px solid black;
    position: absolute;
    left: 0%;
    top: 50%;
    transform: translate(-50%, -50%) scale(1);

    z-index: 15;
    transition: transform 0.3s;
}

.playback-time {
    display: flex;
    align-items: center;
    width: 100%;
    margin: .5em ;
    justify-content: space-between;


}
