.audio-player-wrapper:before {
    content: " ";
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    opacity: .3;
    backdrop-filter: blur(1px);
    z-index: -1;
    background: url("https://res.cloudinary.com/crunchbase-production/image/upload/c_lpad,f_auto,q_auto:eco,dpr_1/k0rypf8osncvnzbplw78") no-repeat fixed center;
}

.audio-player-wrapper {
    font-family: "Lato", "Arial", sans-serif;
    z-index: 13;
    position: absolute;
    display: flex;
    align-items: center;
    flex-direction: column;
    border-radius: 2rem;
    max-width: 760px;
    height: 100%;
    width: 100%;
    overflow: hidden;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    box-shadow: 1px 1px 10px 2px rgba(0, 0, 0, 0.2);
}


.player {
    position: absolute;
    display: flex;
    flex-direction: column;
    justify-content: space-around;
    top: 0;
    left: auto;
    transition: width 500ms linear 250ms, top 500ms linear 250ms, left 500ms linear 250ms;
    height: 100%;
    width: 100%;
}

.player--bottom {
    top: 100%;
}

.player--left {
    left: -100%;
}