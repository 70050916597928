
.steady-button {
    height: 38px;
    border-radius: .375rem;
    min-width: 150px;
    font-size: .67rem;
    line-height: calc(36px);
    text-transform: uppercase;
    letter-spacing: .12em;
    text-align: center;
    cursor: pointer;
    font-weight: bold;
    text-decoration: none;
    display: flex;
    padding: 0 20px;
    transition: border .2s ease 0s,color .2s ease 0s,background-color .2s ease 0s,box-shadow .2s ease 0s;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
    max-width: 100%;
    box-shadow: 0 0 16px rgba(41,30,56,.3);
    border: 1px solid #291e38;
    background-color: #291e38;
    color: #fff;
    box-sizing: border-box;
    justify-content: flex-start;
    align-items: center;
}

span.backend_publication_steady_login_settings__preview__button__separator {
    margin-right: 14px;
    margin-left: 14px;
    width: 2px;
    height: 1.5em;
    border-radius: 2px;
    background-color: white;
}