.episode-view {
    height: 100%;
    padding: 2rem 1.5rem;
    margin-top: 4rem;
    width: 100%;
    position: absolute;
    display: flex;
    flex-direction: column;
    left: 100%;
    overflow-y: scroll;
    transition: top 500ms linear 250ms, left 500ms linear 250ms;
    top: 0;
}

.episode-view.opened {
    left: 0px;
}

.episode-view.unfocused {
    top: 100%;
}

.episode-view input {}

.episode-view > .input-group {
    position: sticky;
    top: 0px;
    z-index: 4;
}

form {
    position: fixed;
    width: 75%;
    padding: 2em;
    border-radius: 1em;
    background-color: white;
    margin: auto;
    font-weight: bold;
    color: #121212;
    transition: top 500ms linear 250ms;
}

.dropdown-menu {
    z-index: 11;
    width: 100%;
}

.dropdown-menu.show {
    display: flex;
    flex-direction: column;
}

.dropdown-item {
    display: inline-flex;
    justify-content: space-between;
    overflow: hidden;
}

.filter-default--innere {
    padding-right: .5rem;
}

.custom-input-group .close-button {
    position: absolute;
    right: 50px;
    z-index: 100;
}

#clearSearchInput {
    margin-left: -24px;
    padding: 4px 2px 4px 4px;
    z-index: 20;
}

.clear-search-input > svg {
    padding: 0;
    width: unset;
}

span.delete-tag {
    cursor: pointer;
    margin-right: 5px;
    font-size: 1.5em;
    line-height: 1em;
    display: flex;
    flex-wrap: nowrap;
}

.delete-icon:hover {
    font-weight: bold;
}

.delete-icon:active {
    /* Slightly darker color when clicked and darken the background */
    color: #c51162;
}

ul.nav-tabs {
    position: sticky;
    top: 3.5rem;
    z-index: 2;
}

.nav-tabs .nav-link {
    background: rgb(230,230,230,0.5);
}


.tab-content {
    overflow: scroll;
    top: 8rem;
    height: 100%;
}

.tab-content > .active {
    margin: 1rem 0;
    display: flex;
    flex-direction: column;
    row-gap: 0.5em;
}

li.nav-item {
    font-weight: bold;

}

.episode-card-footer {
    display: flex;
    justify-content: space-between;
}

.filter-toggle__span {
    width: 100%;
    padding-left: .5rem;
}

.filter-toggle__input {
    margin: auto 0;
}